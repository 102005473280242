import React, { useState, useMemo } from "react";
import "./App.css";
import dikshaImg from './assets/diksha.jpg'

const TITLE = "Diksha Joshi";
const SUBTITLE = "LinkedIn Ghostwriter";

const BUTTON_MAP = [
  {
    name: "Hire Me",
  },
  {
    name: "About",
  },
];

const CONTENT_MAP = [
  {
    type: "Hire Me",
    content: () => {
      return (
        <>
          <p className="text-xl sm:text-xl font-semibold">
            Let's Get You Noticed!
          </p>
          <br />
          <p>
            Hey there, future LinkedIn star! I'm Diksha, and I'm on a mission to
            help professionals like you shine online. Tired of blending into the
            LinkedIn crowd? Let's change that.
          </p>
          <br />
          <p className="text-xl sm:text-xl font-semibold">
            What I Bring to the Table:
          </p>
          <br />
          <p>
            <span className="font-semibold">
              LinkedIn Profile Transformation:
            </span>{" "}
            We're not just updating; we're revolutionizing how you present
            yourself online.
          </p>
          <br />
          <p className="text-xl sm:text-xl font-semibold">
            My Secret Sauce (AKA The Process):
          </p>
          <br />
          <ul className="list-inside list-disc">
            <li>
              <span className="font-semibold">Coffee & Dreams:</span> We'll chat
              about where you want your career to go. Sky's the limit!
            </li>
            <li>
              <span className="font-semibold">Handshake Time:</span> A small
              investment from you to kickstart our journey
            </li>
            <li>
              <span className="font-semibold">Deep Dive:</span> I'll uncover the
              gems in your professional story.
            </li>
            <li>
              <span className="font-semibold">Magic Happens:</span> Your profile
              gets the VIP treatment.
            </li>
          </ul>
          <br />
          <p className="text-xl sm:text-xl font-semibold">Real Talk:</p>
          <p>
            This isn't about buzzwords or corporate jargon. It's about showing
            the real you – the professional who's too busy crushing it to
            perfect their LinkedIn game. That's where I come in.
          </p>
          <br />
          <p>
            Ready to turn heads on LinkedIn? Let's do this! Grab a spot on my
            calendar:{" "}
            <a
              href="https://calendly.com/joshidiksha1996/30min"
              className="text-blue-600 visited:text-purple-600"
            >
              https://calendly.com/joshidiksha1996/30min
            </a>
          </p>
          <br />
          <p>
          <span className="font-semibold">P.S. Fair warning:</span> Side effects may include increased profile views,
            connection requests, and exciting job opportunities. Proceed with
            caution!
          </p>
        </>
      );
    },
  },
  {
    type: "About",
    content: () => {
      return (
        <>
          <p>Hey there! Diksha here 👋</p>
          <br />
          <p>
            So, you want to know about me? Well, I'm a bit of a mixed bag. I
            love telling stories, but I'm also a total nerd when it comes to
            marketing. And books? Don't get me started - I could talk about them
            all day
          </p>
          <br />
          <p>
            My career's been a bit of a rollercoaster. Started off at Josh
            Talks, digging into stories about people who've beaten the odds. It
            was pretty cool, actually.
          </p>
          <br />
          <p>
            Then COVID hit, and suddenly I'm figuring out digital marketing from
            my bedroom. I was working with this company, Mehnaj Software. Spent
            my days making social media posts and trying to crack the SEO code
            for some client in the UK. Somehow managed to get a couple of
            keywords on Google's first page. Beginner's luck, maybe?
          </p>
          <br />
          <p>
            Just when I thought I had it figured out, I jumped into influencer
            marketing with <span className="font-semibold">IPLIX Media</span>.
            Talk about being thrown in the deep end! But you know what? I kind
            of loved it. Started off writing proposals, then ended up managing
            whole campaigns. Worked with some big names too -{" "}
            <span className="font-semibold">Skillshare</span>,{" "}
            <span className="font-semibold">Mamaearth</span>, even{" "}
            <span className="font-semibold">Uber</span>.
          </p>
          <br />
          <p>
            So yeah, that's me. A bit all over the place, but always up for
            learning something new. If you're looking for someone to help tell
            your brand's story, give me a shout. I promise I'll give it my all,
            even if I'm figuring some stuff out as I go!
          </p>
        </>
      );
    },
  },
];

const Tabs = ({
  selectedIndex,
  onTabChanged,
}: {
  selectedIndex: number;
  onTabChanged: (index: number) => void;
}) => {
  const selectedClassName = useMemo(
    () =>
      "px-4 py-2 font-semibold border-b-2 border-blue-400 rounded-t opacity-90",
    []
  );
  const unselectedClassName = useMemo(
    () => "px-4 py-2 font-semibold text-gray-800 rounded-t opacity-50",
    []
  );

  return (
    <div className="relative pt-12">
      <ul className="inline-flex w-full">
        {BUTTON_MAP.map((item, index) => (
          <li
            className={
              index === selectedIndex ? selectedClassName : unselectedClassName
            }
          >
            <button onClick={() => onTabChanged(index)}>{item.name}</button>
          </li>
        ))}
      </ul>
      <div className="absolute inset-x-0 bottom-0 border-b-2 border-grey-400 rounded-t opacity-50"></div>
    </div>
  );
};

const App = () => {
  const [contentIndex, setContentIndex] = useState<number>(0);

  return (
    <div className="flex min-h-screen">
      <div className="mx-auto max-w-xl p-4 sm:p-12">
        <div className="flex justify-around">
          <div className="w-2/3 flex-col justify-center">
            <h1 className="text-4xl sm:text-5xl font-semibold">{TITLE}</h1>
            <p className="text-md sm:text-2xl text-gray-500">{SUBTITLE}</p>
          </div>
          <div className="w-40">
            <img
              src={dikshaImg}
              alt="Diksha Joshi"
              className="w-full h-auto rounded"
            />
          </div>
        </div>
        <Tabs
          selectedIndex={contentIndex}
          onTabChanged={(index) => {
            setContentIndex(index);
          }}
        />
        <div className="text-gray-700 pt-8">
          {CONTENT_MAP[contentIndex]?.content()}
        </div>
      </div>
    </div>
  );
};

export default App;
